import { NineActions, NineButton } from "@9amhealth/wcl/generated/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";
import translate from "src/lib/translate";
import { useBlocNext } from "@blac/react";
import AppPageTitle from "src/ui/components/AppPageTitle/AppPageTitle";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import Data from "src/ui/components/Data/Data";
import DocumentPreview from "src/ui/components/DocumentPreview/DocumentPreview";
import { PROFILE_URL } from "src/ui/components/ProfileOverview/ProfileOverview";
import OverlayBackButton from "src/ui/components/StyledComponents/OverlayBackButton";
import Translate from "src/ui/components/Translate/Translate";
import PatientPharmacyInsuranceBloc from "src/state/PatientPharmacyInsuranceBloc/PatientPharmacyInsuranceBloc";
import MedicalInsuranceFormBloc from "components/MedicalInsuranceForm/MedicalInsuranceFormBloc";
import { ActionSheet, ActionSheetButtonStyle } from "@capacitor/action-sheet";
import reportErrorSentry from "src/lib/reportErrorSentry";
import {
  AppPopup,
  AppQueryPopupsController
} from "components/AppQueryPopups/AppQueryPopupsBloc";
import toTitleCase from "src/lib/toTitleCase";

const CardImages = styled.div`
  display: flex;
  margin-top: -0.67rem;
  margin-bottom: 1rem;
  gap: 0.4rem;
`;

export const PROFILE_INSURANCE_INFO_ROUTE = "/app/profile/insurance";
const InsuranceInformationDetail: FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [{ currentPrimaryPharmacyInsurance }, { loadInsurances }] = useBlocNext(
    PatientPharmacyInsuranceBloc
  );
  const [{ currentPrimaryMedicalInsurance }, { loadPrimaryPlan }] = useBlocNext(
    MedicalInsuranceFormBloc
  );

  useEffect(() => {
    void loadInsurances().finally(() => setLoading(false));
  }, []);

  const onUpdateMedicalInsurance = () => {
    AppQueryPopupsController.openPopup(AppPopup.medicalInsurance, {
      onEvent: {
        popupClosed: () => {
          setLoading(true);
          void loadPrimaryPlan().finally(() => setLoading(false));
        }
      }
    });
  };

  const onUpdatePharmacyInsurance = () => {
    AppQueryPopupsController.openPopup(AppPopup.profileEnterPharmacyInsurance, {
      onEvent: {
        popupClosed: () => {
          setLoading(true);
          void loadInsurances().finally(() => setLoading(false));
        }
      }
    });
  };

  const openSelect = async () => {
    try {
      const result = await ActionSheet.showActions({
        title: translate("insurance.select.title"),
        message: translate("insurance.select.description"),
        options: [
          {
            title: translate("insurance.medical"),
            style: ActionSheetButtonStyle.Default
          },
          {
            title: translate("insurance.pharmacy"),
            style: ActionSheetButtonStyle.Default
          },
          {
            title: translate("cancel"),
            style: ActionSheetButtonStyle.Cancel
          }
        ]
      });
      const medical = result.index === 0;
      const pharmacy = result.index === 1;

      if (medical) {
        onUpdateMedicalInsurance();
      } else if (pharmacy) {
        onUpdatePharmacyInsurance();
      }
    } catch (e: unknown) {
      reportErrorSentry(e);
    }
  };

  return (
    <div>
      <OverlayBackButton show alwaysUseFallback fallbackUrl={PROFILE_URL} />
      <AppPageTitle title={translate("insuranceInformation")}></AppPageTitle>
      <nine-spacer s={"md"}></nine-spacer>

      {currentPrimaryPharmacyInsurance && (
        <AsyncContent height="30rem" check={[!loading]}>
          <Data.Table
            action={{
              onAction: onUpdatePharmacyInsurance
            }}
            title={<Translate msg="insurance.pharmacy" />}
          >
            <Data.Row
              title="Rx BIN / IIN"
              data={currentPrimaryPharmacyInsurance.rxBin}
            />
            <Data.Row
              title="Rx Group ID"
              data={currentPrimaryPharmacyInsurance.rxGrp}
            />
            <Data.Row
              title={translate("cardholderId")}
              data={currentPrimaryPharmacyInsurance.cardHolderId}
            />
            <Data.Row
              title="Rx PCN"
              data={currentPrimaryPharmacyInsurance.rxPcn}
            />
          </Data.Table>
          {currentPrimaryPharmacyInsurance.frontPhotoFileId &&
            currentPrimaryPharmacyInsurance.backPhotoFileId && (
              <CardImages>
                <DocumentPreview
                  id={currentPrimaryPharmacyInsurance.frontPhotoFileId}
                />
                <DocumentPreview
                  id={currentPrimaryPharmacyInsurance.backPhotoFileId}
                />
              </CardImages>
            )}
          <nine-spacer s={"sm"}></nine-spacer>
        </AsyncContent>
      )}

      {currentPrimaryMedicalInsurance && (
        <AsyncContent height="30rem" check={[!loading]}>
          <Data.Table
            action={{
              onAction: onUpdateMedicalInsurance
            }}
            title={<Translate msg="insurance.medical" />}
          >
            <Data.Row
              title={translate("groupNumber")}
              data={currentPrimaryMedicalInsurance.groupNumber}
            />
            <Data.Row
              title={translate("cardholderId")}
              data={currentPrimaryMedicalInsurance.cardHolderId}
            />
            <Data.Row
              title={translate("planOwner")}
              data={toTitleCase(
                currentPrimaryMedicalInsurance.primaryPlanOwner
              )}
            />
          </Data.Table>
          {currentPrimaryMedicalInsurance.frontPhotoFileId &&
            currentPrimaryMedicalInsurance.backPhotoFileId && (
              <CardImages>
                <DocumentPreview
                  id={currentPrimaryMedicalInsurance.frontPhotoFileId}
                />
                <DocumentPreview
                  id={currentPrimaryMedicalInsurance.backPhotoFileId}
                />
              </CardImages>
            )}
          <nine-spacer s={"sm"}></nine-spacer>
        </AsyncContent>
      )}

      {!(currentPrimaryMedicalInsurance && currentPrimaryPharmacyInsurance) && (
        <NineActions style={{ justifyContent: "center" }}>
          <NineButton onClick={() => void openSelect()}>
            <Translate msg="insurance.add" />
          </NineButton>
        </NineActions>
      )}
    </div>
  );
};

export default InsuranceInformationDetail;
